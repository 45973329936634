
<template>
  <div class="selectDeliverShop">
    <van-popup
      v-model="showPicker"
      class="back-popup"
      get-container="#app"
      position="right"
      :style="{ height: '100%', width: '100%' }"
      :overlay-style="{ background: '#fff' }">
      <div id="header-popup">
        <div class="opt" @click="cancel">
          <slot name="header-left">
            <van-icon name="arrow-left" size="18"></van-icon>
          </slot>
        </div>
        <div id="title">
          <slot name="header-title">{{ i18n.t('请选择') }}</slot>
        </div>
        <!-- <div class="opt" @click="confirm">
          <slot name="header-right"> {{ i18n.t('确认') }} </slot>
        </div> -->
      </div>
      <div>
        <slot name="content">
          <p v-if="dealerList.length === 0" class="no-data">{{ i18n.t('noData') }}</p>
          <van-cell
            v-for="(item, index) in dealerList"
            :key="index"
            :class="{ active: isActive(index) }"
            clickable
            border
            :title="item.dictName"
            @click="onChange(item, index)">
            <template #right-icon>
              <van-icon
                v-show="isActive(index)"
                name="success"
                size="22"
                color="#B9921A"
              ></van-icon>
            </template>
          </van-cell>
          <p class="searchMore" @click="searchMore">{{ i18n.t('查询更多门店') }}</p>
        </slot>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { i18n } from '@/main'
import { isArray } from '@/utils'
import selectShop from '@/components/select-shop'
export default {
  props: {
    dealerList: {
      type: Array,
      default: () => []
    },
    cellList: {
      type: Array,
      default: () => []
    },
    lastSelect: {
      type: [Number, String, Array],
      default: 0,
    },
  },
  data () {
    return {
      i18n,
      actives: [],
      showPicker: true,
    }
  },
  computed: {
    
  },
  watch: {
    lastSelect: {
      handler(newVal) {
        if (isArray(newVal)){
          newVal.forEach((code) => {
            this.actives.push(this.dealerList.findIndex(item => {
              return item.dictCode === code
            }))
          })
        } else if (newVal){
          this.actives.push(this.dealerList.findIndex(item => (item.dictCode === newVal)))
        }
      },
      immediate: true,
    }
  },
  methods: {
    isActive(index) {
      return this.actives.includes(index)
    },
    onChange(item, index) {
      if (this.lastSelect) this.actives = []
      if (this.actives.includes(index)) {
        const _i = this.actives.indexOf(index)
        this.actives.splice(_i, 1)
        this.$emit('confirm', {})
      } else {
        this.actives.splice(0, 1, index)
        this.$emit('confirm', item)
      }
    },
    searchMore() {
      selectShop({ requestList: this.cellList, lastSelect: this.lastSelect, showSearchForShop: true }).then(res => {
        this.$emit('confirm', res)
      }).catch(() => {
      })
    },
    cancel() {
      this.$emit('cancel')
    },
  }
}
</script>
<style lang="less" scoped>
.selectDeliverShop{
}
/deep/ .searchMore{
  width: 100%;
  line-height: 50px;
  color: @yellow-text;
  cursor: pointer;
  text-align: center;
}
</style>