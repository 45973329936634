import Vue from 'vue'
import Selector from './selector'
import router from '@/router'

const SelectorConstructor = Vue.extend(Selector)

let instance = null

export default options => {
  if (!instance) {
    instance = new SelectorConstructor({
      el: window.document.createElement('div')
    })
    window.document.body.appendChild(instance.$el)
  }
  router.afterEach(to => {
    if (instance){
      instance.reject()
      instance.showPicker=false
    }
  })
  return new Promise((resolve, reject) => {
    instance.options = options
    instance.getData()
    instance.showPicker = true
    instance.resolve = resolve
    instance.reject = reject
  })
    .finally(() => {
      window.setTimeout(() => {
        instance.$el.remove()
        instance.$destroy()
        instance = null
      }, 200)
    })
}