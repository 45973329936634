import Vue from 'vue'
import Selector from './selector'
import router from '@/router'

const SelectorConstructor = Vue.extend(Selector)

let instance = null
export default options => {
  if (!instance) {
    instance = new SelectorConstructor({
      el: window.document.createElement('div')
    })
    window.document.body.appendChild(instance.$el)
  }
  router.afterEach(to => {
    if (instance){
      instance.reject()
      instance.showPicker=false
    }
  })
  return new Promise((resolve, reject) => {
    instance.min = options.min
    instance.isDisableTips = options.isDisableTips
    instance.lastSelect = options.lastSelect
    instance.dictType = options.dictType
    instance.multiple = options.multiple ? true : false
    instance.shopId = options.shopId
    instance.showPicker = true
    instance.excludeCode = options.excludeCode
    instance.reasonList = options.reasonList
    instance.contactResultList = options.contactResultList
    instance.resolve = resolve
    instance.reject = reject
    instance.cellOptions = options.cellOptions || [] // 默认数据源
    options.initOptions && (instance.initOptions = options.initOptions)
  })
    .finally(() => {
      window.setTimeout(() => {
        instance.$el.remove()
        instance.$destroy()
        instance = null
      }, 200)
    })
}
