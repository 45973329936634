// 前端假数据字典
export default {
  2010: {
    dictType: 2010,
    id: null,
    remark: '预算金额',
    dictList: [
      { dictCode: 10021001, dictName: '6万以下', pcode: 0 },
      { dictCode: 10021002, dictName: '6-8万', pcode: 0 },
      { dictCode: 10021007, dictName: '8-10万', pcode: 0 },
      { dictCode: 10021003, dictName: '10-12万', pcode: 0 },
      { dictCode: 10021004, dictName: '12-14万', pcode: 0 },
      { dictCode: 10021005, dictName: '14-16万', pcode: 0 },
      { dictCode: 10021006, dictName: '16万以上', pcode: 0 }
    ]
  },
  10521: {
    dictType: 10521,
    id: null,
    remark: '关怀结果',
    dictList: [
      { dictCode: 10520006, dictName: '未成功回访', pcode: 0 },
      { dictCode: 10520007, dictName: '成功回访', pcode: 0 },
    ]
  },
  20000: {
    dictType: 2000,
    id: null,
    remark: '关怀满意度',
    dictList: [
      { dictCode: 20001008, dictName: '非常满意', pcode: 0 },
      { dictCode: 20001009, dictName: '一般', pcode: 0 },
      { dictCode: 20001010, dictName: '有问题需要解决', pcode: 0 },
    ]
  },
  99990: {
    dictType:  99990,
    id: null,
    remark: '发票类型',
    dictList: [
      { dictCode: '004', dictName: '增值税专用票', pcode: 0 },
      { dictCode: '005', dictName: '机动车销售统一发票', pcode: 0 },
      // { dictCode: '006', dictName: '二手车销售统一发票', pcode: 0 },
      { dictCode: '007', dictName: '增值税普通发票', pcode: 0 },
      // { dictCode: '026', dictName: '增值税电子普通发票', pcode: 0 },
      // { dictCode: '028', dictName: '增值税电子专用发票', pcode: 0 },
    ],
  }
}
