<script>
import formSelectWrap from '../common-selector/form-select-wrap'
import baseDataServices from '@/services/baseDataServices'
import store from '@/store'
export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrap
  },
  data() {
    return {
      list: [],
      showPicker: false,
      options: null,
      lastSelect: null,
      showSearchForShop: false
    }
  },
  computed: {
  },
  methods: {
    async getData() {
      const { requestList } = this.options
      const { dealers=[] } = store.getters.userInfo
      if (requestList && Array.isArray(requestList)) {
        this.shops = requestList.map((item) => {
          return {
            id: item.dealerCode,
            dictCode: item.dealerCode,
            dictName: item.dealerName,
            ...item
          }
        })
        return false
      }
      baseDataServices.getDealerDetail({ id: dealers[0].id }).then(res=>{
        this.shops = res?.relations || []
      })
    },
    confirm(data) {
      this.resolve(data)
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  }
}
</script>
<template>
  <form-select-wrap
    :showPicker="showPicker"
    :cell-list="shops"
    :multiple="false"
    :lastSelect="lastSelect"
    :showSearchForShop="showSearchForShop"
    :selectShop="true"
    @cancel="cancel"
    @confirm="confirm">
  </form-select-wrap>
</template>
