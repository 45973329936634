<script>
import formSelectWrap from '../common-selector/form-select-wrap'
import baseDataServices from '@/services/baseDataServices'

export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrap
  },
  data() {
    return {
      list: [],
      showPicker: false,
      options: null
    }
  },
  methods: {
    async getData() {
      // initOptions:默认选中数据 requestList:外部数据源
      const { seriesCode,modelCode,colourCode,interiorCode, initOptions=[], requestList } = this.options
      if (requestList && Array.isArray(requestList)) {
        this.list = requestList
      } else {
        const data = await baseDataServices.modelSalesOptions({ seriesCode,modelCode,colourCode,interiorCode })
        if (!data.length) return
        this.list = data.map(item => {
          return {
            id: item.optionsCode,
            dictCode: item.optionsCode,
            dictName: item.optionsName,
            optionsNameEn: item.optionsNameEn
          }
        })
      }
      if (Array.isArray(initOptions) && initOptions.length){
        const arr = []
        this.list.forEach(({ dictCode },index) => {
          if (initOptions.includes(dictCode)){
            arr.push(index)
          }
        })
        this.$refs.formSelectWrap.actives = arr
      }
    },
    confirm(data) {
      this.resolve(data)
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  }
}
</script>
<template>
  <form-select-wrap ref="formSelectWrap"
                    :showPicker="showPicker"
                    :cell-list="list"
                    :multiple="true"
                    @cancel="cancel"
                    @confirm="confirm">
  </form-select-wrap>

</template>
