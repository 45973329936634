<script>
import formSelectWrap from '../common-selector/form-select-wrap'
import baseDataServices from '@/services/baseDataServices'

export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrap
  },
  data() {
    return {
      list: [],
      showPicker: false,
      options: null
    }
  },
  methods: {
    async getData() {
      const { type, params } = this.options
      if (!type) return
      // 判断是否有传入数据源
      const { requestList } = params
      if (requestList && Array.isArray(requestList)) {
        this.list = requestList
        return false
      }
      const serviceUrl = type === 'out' ? 'modelSalesColours' : 'modelSalesInteriors'
      // 根据参数请求API
      const data = await baseDataServices[serviceUrl](params)
      if (!data.length || !data[0]?.code) return
      this.list = data.map(item => {
        return {
          id: item.code,
          dictCode: item.code,
          dictName: item.name,
          nameEn: item.nameEn
        }
      })
    },
    confirm(data) {
      this.resolve(data)
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  }
}
</script>
<template>
  <form-select-wrap :showPicker="showPicker"
                    :cell-list="list"
                    :multiple="false"
                    @cancel="cancel"
                    @confirm="confirm">
  </form-select-wrap>

</template>
